<template>
  <div>
    <Breadcrumbs page_title="event" :items="breadcrumbs" />
    <template>
      <v-container fluid>
        <v-row v-if="hasData">
          <v-col cols="12" xl="4" lg="6" md="6"  v-for="item in sortedItems" :key="item.id">
            <v-card class="pa-2 rounded-xl" @click="viewEvent(item)">
              <v-row>
                <v-col cols="12" xl="7" lg="6" md="7" class="pr-1" style="padding-left: 10px;">
                  <v-img :src="item.optimizeLink" class="eventimg"></v-img>
                </v-col>
                <v-col cols="12" xl="5" lg="6" md="5" class="pl-1 pr-2">
                  <div class="d-flex flex-no-wrap eventtext">
                    <div class="mt-5">
                      <v-card-title>
                        <div class="" style="font-weight: bold;">{{ item.courseName }}</div>
                      </v-card-title>

                      <v-card-subtitle
                        class="pt-3 startdate pl-1 pb-1 pr-1"
                      >{{ $t("startdate") }} : {{ item.startContentDate }}</v-card-subtitle>
                      <v-card-subtitle
                        class="pt-0 pl-1 pb-1 pr-1"
                      >{{ $t("locationsm") }} : {{ item.courseLocation }}</v-card-subtitle>
                      <v-card-subtitle
                        class="pt-0 pl-1 pb-1 pr-1"
                      >{{ $t("time") }} : {{ item.courseTime }}</v-card-subtitle>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mx-3 my-5" v-else-if="loading">
          <v-col cols="4" v-for="n in 6" :key="n">
            <v-skeleton-loader class="mx-auto" max-height="220" type="image"></v-skeleton-loader>
          </v-col>
        </v-row>

        <v-card class="noarticle mx-3 my-5 rounded-lg d-flex justify-center" v-else>
          <div class="text-center nodata">{{ $t("noevent") }}</div>
        </v-card>
      </v-container>
    </template>
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {
      items: [],
      breadcrumbs: [
        // {
        //   sidebar_tilte: null,
        // },
        {
          text: "event"
        }
      ],
      hasData: false,
      loading: true
    };
  },
  mounted() {
    this.getFavourite();
    this.getNotificationCount();
    this.getEvent();
  },
  computed: {
    sortedItems() {
      return [...this.items].sort(
        (a, b) => new Date(b.startContentDate) - new Date(a.startContentDate)
      );
    }
  },
  methods: {
    async getEvent() {
      let self = this;
      const res = await self.$axios.get(
        `${self.web_url}Courses/GetAllCourse?id=` +
          localStorage.getItem("companyID")
      );
      if (res.data.data.length > 0) {
        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          startContentDate: moment(v.startContentDate).format("DD/MM/YYYY"),
          courseTime: moment(v.courseTime)
            .local()
            .format("LT")
        }));
       // self.items.reverse();
        this.loading = false;
        this.hasData = true;
      } else {
        this.loading = false;
        this.hasData = false;
      }
    },
    viewEvent(item) {
      this.$store.commit("savenotificationTransactionIDForEvent", 0);
      this.$router.push({
        path: "/event/" + item.courseName.split("?").join(""),
        query: { course_id: item.id, eventName: item.courseName }
      });

      localStorage.setItem("EventName", item.courseName);
      localStorage.setItem("course_id", item.id);
    }
  }
};
</script>

<style scoped>
.startdate {
  color: #4fb14e;
  font-size: 15px !important;
}
.text-h5 {
  font-size: 20px !important;
}
.noarticle {
  height: 200px;
  align-items: center;
}
.nodata {
  color: #a4a4a4;
  font-size: 20px !important;
}
.v-card__title {
  padding: 4px !important;
}
.eventimg {
    border-radius: 18px;
  }
</style>


<style scoped>
@media (max-width: 1440px) {
  .startdate {
    color: #4fb14e;
    font-size: 13px !important;
  }
  .eventimg {
    height: 165px !important;
    border-radius: 18px;
  }
  .text-truncate {
    font-size: 18px;
    max-width: 125px;
    /* white-space: nowrap !important; */
  }
}
@media (min-width: 1440px) and (max-width: 1980px) {
  .eventimg {
    margin: 15px;
    height: 200px !important;
    border-radius: 18px;
    width: 280px!important;
  }
}
@media (min-width: 1920px){
  .eventimg {
    height: 160px !important;
    border-radius: 18px;
    width: 280px!important;
  }
}
@media (max-width: 1440px){
  .eventimg {
    margin: 15px;
    height: 190px !important;
    border-radius: 18px;
    width: 250px!important;
  }
}
@media (max-width: 1366px) and (min-width: 1264px){
  .eventtext {
    margin-left: 20px;
  }
}
::v-deep .v-btn__content {
  padding-top: 4px !important;
}
</style>
